import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import LabelLang from 'src/lang/components/LabelLang';
import cryptosSvg from './cryptos.svg';

const CryptoStyled = styled.div`
  background: #F7F7F7;
  > div {
    padding: 20px;
    margin: 0 auto 0 auto !important;
    width: 100%;
    max-width: 1440px;
    p {
      margin-left: 50px;
      font-size: 15px;
      color: #333333;
      margin-bottom: 0;
    }
    @media screen and (max-width: 768px) {
      padding: 0px;
      margin: 0;
      
      p {
        margin-left: unset;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    > div:first-child {
      padding-left: 50px;
      margin: auto;
      border-right: 2px solid #D8D8D8;
      @media screen and (max-width: 768px) {
        padding: 20px;
        text-align: center;
        flex-direction: column;
      }
    }
    >div:nth-child(2) {
      p {
        max-width: 508px;
      }
    }
  }
`;

const Crypto = (props) => {
  const icon = props.icon || cryptosSvg;
  const desc = props.desc || 'newHome.crypto.desc';
  return (
    <CryptoStyled>
      <Row>
        <Col md={6}>
          <img src={icon} alt="MyConstant crypto" />
        </Col>
        <Col md={6}>
          <p className=""><LabelLang id={desc} /></p>
        </Col>
      </Row>
    </CryptoStyled>
  );
};

export default Crypto;
