import React, { useState } from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import cx from 'classnames';

import { LabelLang, WrapperLang } from 'src/lang/components';
import { SPACING_HORIZONTAL } from 'src/assets/themes/spacing';

import MyPopover from './MyPopover';
import PickerMobileDefault from './PickerMobile';

export { default as Cryptos } from './cryptos';
export const PickerMobile = PickerMobileDefault;
export const MyPopoverComp = MyPopover;
export const FormStyled = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(116, 121, 152, 0.15);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;

  @media screen and (min-width: 768px) {
    div[class^='col-md'] {
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    div[class^='col'],
    .col-12 {
      padding: 0px;
    }
  }

  ${props =>
    props.proStyled &&
    css`
      background: #212121;
      padding: 10px;
      box-shadow: none;
      h6 {
        color: #ffffff;
      }

      input[type='text'] {
        color: #ffffff;
        height: 40px;
        font-size: 14px;
        background-color: #202020;
        border: 1px solid #3a3a3a;
        text-overflow: eclippse;
        overflow: hidden;
        ~ span {
          cursor: pointer;
          display: inline-flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          top: 0;
          right: 0;
          min-width: 64px;
          height: 40px;
          background-color: #3a3a3a;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }

      canvas {
        border: 1px solid #ffffff;
        padding: 5px;
        background-color: #ffffff;
      }

      button[type='submit'] {
        height: 40px;
        font-size: 14px;
        text-transform: uppercase;
      }
      &.mobile {
        background: none;
        margin-top: 0px;
        padding: 0px 0px 0px 10px !important;
        .vertical {
          flex-direction: column;
        }
        label {
          color: #fff !important;
        }
        div[class*='dropdown-warper'],
        div[class*='react-select__control'],
        input {
          background-color: #14223a !important;
          border: none !important;
          margin: 0px !important;
        }
        span[class*='input-group-text'] {
          background-color: transparent !important;
          border: none !important;
        }
      }
    `};

  @media screen and (max-width: 768px) {
    padding: 20px 15px;
    margin: unset;
    /* margin-top: -60px; */
    border-radius: 10px;
  }
  @media screen and (min-width: 1300px) {
    margin: 0 20px;
  }
`;

export const RowStyled = styled(Row)`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
`;

export const ColStyled = styled(Col)`
  @media screen and (max-width: 768px) {
    padding: 0px ${SPACING_HORIZONTAL - 5}px;
    background-color: transparent;
    background-position-x: 25%;
    background-position-y: top;
  }
`;

export const FormColStyled = styled(ColStyled)`
  background-image: url(${props => props.bg && props.bg});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  padding-top: ${props => (props.bg ? '50px' : '0px')};
  @media screen and (max-width: 768px) {
    background-image: url(${props => props.bg && props.bg});
    background-position-x: -15vw, 85vw;
    background-position-y: top, 120px;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
  }
`;

const InputStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.inline {
    flex-direction: row;
    padding: 10px 0px;
  }
  .labelWrapper {
    z-index: 1;
    margin-bottom: -7px;
    padding-left: 10px;
    height: 20px;
    text-align: left;
    position: relative;
    label {
      font-weight: 300;
      font-size: 11px;
      color: #58667E;
      margin-bottom: 0;
      padding: 0 2px;
      background: white;
      white-space: nowrap;
      line-height: 11px;
      
      i.fa-question-circle {
        color: #A6B0C2;
      }
    }
  }
  input {
    width: 100%;
  }
  .form-group {
    margin-bottom: 5px;
  }
`;

export const Input = ({ label, desc, ...restProps }) => (
  <InputStyled>
    <div>
      <label>
        {label}
        {desc && <MyPopover desc={desc} />}
      </label>
    </div>
    <input {...restProps} />
  </InputStyled>
);

export const CustomInput = ({
  label, labelClassName = '', labelContainerClassName='', hideLabel = false,
  desc,
  children,
  type,
  className,
  values,
  ...restProps
}) => (
  <InputStyled className={className}>
    {label && (
      <div className={cx('labelWrapper', labelContainerClassName)}>
        {
          !hideLabel && (
            <label className={labelClassName}>
              {label}
              {desc && <MyPopover type={type} desc={desc} values={values} />}
            </label>
          )
        }
      </div>
    )}
    {children}
  </InputStyled>
);

export const MaterialCustomInput = styled.div` // props: primary, secondary, background
  .form-control {
    color: ${props => props.secondary || '#8F92A1'};

    .MuiFormControl-root.border-danger {
      .MuiInputBase-input {
        border-color: #ff5858 !important;
      }
    }
    .MuiFormControl-root {
      width: 100%;
      label {
        font-size: 13px;
        top: -3px;
        color: ${props => props.secondary || '#8F92A1'};
        padding: 0 10px;
        
        &.Mui-focused {
          color: #3f51b5;
        }
        
        &.MuiInputLabel-shrink {
          top: 0;
          background-color: ${props => props.background || '#F9F9FA'};
        }
      }
      .MuiInputBase-input {
        border: 1px solid #ededed;
      }
      .MuiOutlinedInput-root {
        input {
          padding: 13px 15px;
          background-color: ${props => props.inputBackground || '#F9F9FA'};
          border-radius: 16px;
          font-size: 14px;
          font-weight: 500;
          font-family: SFProDisplay, sans-serif;
          height: 20px;
          color: ${props => props.primary || '#646464'};
          border-color: ${props => props.border || '#ededed'};

          &[type=password] {
            padding-right: 30px;
          }
        }

        .MuiSelect-outlined {
          border-radius: 8px;
        }

        .MuiOutlinedInput-input {
          padding: 13px 15px;
        }
        
        fieldset {
          display: none;
          border-radius: 16px;
          border-width: 1px !important;
        }
        
        &.Mui-focused, &:hover, &:focus, &:active {
          input {
            border-color: #3f51b5;
          }
        }
      }
    }
  }
`;

export const BlockContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'center'};
  @media screen and (max-width: 768px) {
    text-align: left;
    .title,
    .desc {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  > h1,
  h2,
  h3,
  h4,
  h5 {
    max-width: ${props => props.maxWidth || 'unset'};
  }
  > img {
    margin-top: 50px;
    width: 100%;
  }
  > div {
    width: 100%;
  }
`;

export const BlockCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'center'};
  img {
    width: unset;
  }
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    text-align: ${props => props.textAlign || 'inherit'};
  }
`;

export const ContentStyled = styled.div`
  margin: 0 auto;
  max-width: ${props => props.maxWidth || '1000px'};
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props =>
    props.contentType
      ? ['unset', 'space-between', 'space-around', 'center'][props.contentType]
      : 'unset'};
  align-items: center;
  flex-wrap: wrap;
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 60px solid green;
`;

export const BoxShadow = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => props.padding || '50px'};
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
  margin-bottom: 20px;
`;

export const InputGroupStyled = styled.div`
  .ref {
    .input-group-prepend {
      height: 45px;
      .input-group-text {
        font-size: 16px;
        border-radius: 0;
      }
    }
    input {
      font-size: 16px;
      height: 45px;
      border-radius: 0;
    }
    @media screen and (max-width: 768px) {
      .input-group-prepend {
        width: 100%;
        .input-group-text {
          width: 100%;
          background-color: white;
          border: none;
          justify-content: center;
          font-size: 15px;
          color: #595959;
        }
      }
      .refInput {
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        color: #000000;
        text-align: center;
        border: none;
        border-radius: 0 !important;
      }
      .btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        > button {
          border-radius: 10px !important;
          background: #349d84;
          font-size: 15px;
          height: 39px;
          width: 48%;
          font-weight: 500;
        }
      }
    }
  }
`;

export const IntroStyled = styled.div`
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;

  .intro {
    padding-top: ${props => props.paddingTop || '5px'};
    height: 100%;
    max-width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  h1, .h1 {
    color: #ffffff;
    font-size: 35px;
    margin-bottom: 20px;
    font-weight: 500;
    line-height: 42px;
    > span {
      font-size: inherit;
    }
    &.hightlight {
      color: #f5a623;
    }
    &.last {
      margin-bottom: 20px;
      //@media screen and (max-width: 768px) {
      //  line-height: 30px;
      //}
    }
  }
  p,
  a {
    color: #ffffff;
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.3px;
  }

  .topText {
    color: #ffffff;
    font-size: 12px;
    font-family: SFMono;
    font-weight: 300;
    @media screen and (max-width: 768px) {
      margin-bottom: 5px;
    }
    &::before {
      content: '';
      background: #f5a623;
      position: absolute;
      height: 2px;
      width: 40px;
      margin-top: -5px;
    }
  }
  .reference {
    font-size: 14px;
    opacity: 0.46;
    color: #ffffff;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
    margin-left: unset;

    h1, .h1 {
      font-weight: 500;
      font-size: 30px;
      color: #FFFFFF;
      line-height: 37px;
      margin-top: 20px;
    }

    .intro {
      max-width: unset;
    }

    .reference {
      font-size: 14px;
    }
  }
`;

export const InputGroupAction = ({
  size = null,
  onSubmit = e => console.log('Submitted', e),
  placeholderKey = 'app.common.placeholder',
  btnKey = 'app.common.btn',
  defaultValue = '',
  validation = e => true,
  appendComp = null,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [valid, setValid] = useState(true);
  const handleOnSubmit = () => onSubmit(value);

  return (
    <InputGroupStyled>
      <InputGroup size={size || 'lg'} className="ref">
        <WrapperLang>
          {ts => (
            <input
              value={value}
              onChange={e => {
                setValue(e.target.value);
                setValid(validation(e.target.value));
              }}
              placeholder={ts(placeholderKey)}
              className="form-control"
            />
          )}
        </WrapperLang>
        <div className="input-group-prepend">
          <Button disabled={!valid} onClick={handleOnSubmit} variant="primary">
            {appendComp || <LabelLang id={btnKey} />}
          </Button>
        </div>
      </InputGroup>
    </InputGroupStyled>
  );
};

export const ScrollViewStyled = styled.div`
  height: ${props => props.height || '400'}px;
  width: 100%;
  overflow-y: scroll;
  > div {
    padding: 5px 5px;
  }
`;

/**
 * grid-template-columns: repeat(${props => props.num}, 1fr);
 * remove live above, so no need num prop
 */
export const GridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${props => props.min || '250px'}, 1fr)
  );
  grid-gap: ${props => props.gap || '10'}px;
`;

export const CardStyled = styled.div`
  display: grid;
  grid-template-rows: ${props => props.template || '120px 50px 1fr'};
  background: ${props => props.bg || '#FFFFFF'};
  border-radius: ${props => props.borderRadius || '10px'};
  padding: ${props => props.padding || '20px'};
  margin: ${props => props.margin || '20px'};

  .cardImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > img {
      height: 66px;
    }
  }
  .cardViewTitle {
    font-weight: 600;
    font-size: 15px;
    color: #151517;
    text-align: center;
  }
  .cardViewDesc {
    color: #676767;
    font-size: 13px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    margin: unset;
  }
`;
export const CardView = ({
  img,
  imgAlt = '',
  title,
  desc,
  ...restPropsStyled
}) => (
  <CardStyled {...restPropsStyled}>
    {img && (
      <div className="cardImg">
        <img alt={imgAlt} src={img} />
      </div>
    )}
    <p className="cardViewTitle">{title}</p>
    <p className="cardViewDesc crowdin">{desc}</p>
  </CardStyled>
);

export const LineHeader = styled.div`
  height: 4px;
  width: 50px;
  max-width: 50px;
  background: ${props => props.bg || '#3E8DEB'};
  border-radius: 4px;
  border: none;
  margin: unset;
  margin-bottom: ${props => props.marginBottom || '0px'};

  ${props =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};

  @media screen and (max-width: 768px) {
    margin: unset;
    margin-left: 20px;
    margin-bottom: 5px;
  }
`;

const ListIconsBannerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${props => props.min || '150px'}, 1fr)
  );
  grid-gap: 10px;
  margin-top: 50px;

  .list-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: #ffffff;
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.3px;
    padding-right: 10px;
    @media screen and (max-width: 768px) {
      font-size: 13px;
      font-weight: 300;
      opacity: 0.9;
      line-height: 18px;
    }

    img {
      margin-right: 10px;
    }

    &::before {
      content: '';
      background: #43a474;
      position: absolute;
      height: 40px;
      width: 2px;
      margin-left: -10px;
      @media screen and (max-width: 768px) {
        margin-left: unset;
        margin-top: -10px;
        height: 3px;
        width: 40px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    margin-top: 40px;
  }
`;

export const ListIconsBanner = ({ data, ...restProps }) => (
  <ListIconsBannerStyled {...restProps}>
    {data.map((e, i) => (
      <div key={i} className="list-item">
        {e.img && <img src={e.img} />}
        <div>{e.title}</div>
      </div>
    ))}
  </ListIconsBannerStyled>
);

export const SimplePageStyled = styled.div`
  color: #8b959a;
  .form {
    max-width: 480px;
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .loginTitle {
    font-size: 13px;
    margin-bottom: 1rem;
    letter-spacing: -0.5px;
  }
  .loginSocialTitle {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .loginEmailTitle {
    margin-top: 2rem;
    margin-bottom: unset;
  }
  .welcomeTitle {
    padding: 0 20px;
    margin-bottom: 20px;
    h1 {
      font-size: 30px;
      color: #171717;
      letter-spacing: 0.3px;
      line-height: 36px;
      margin-bottom: 0;
    }
  }
  .logoForm {
    text-align: center;
    @media screen and (min-width: 768px) {
      img {
        display: none;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .welcomeTitle {
      h1  {
        font-size: 26px;
      }
    }
    .form {
      padding: 15px 20px;
    }
  }
`;

export const SimpleFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    height: 46px;
    background: #0052CC;
    border-radius: 16px;
    font-size: 14px;
    &:hover,
    &:active {
      background: #0052CC !important;
    }
    min-width: 268px;
  }

  .form-control {
    .MuiFormControl-root {
      label {
        padding: 0;
      }
    }
  }
`;

export const ListStyled = styled.div`
  .historyItem {
    grid-template-columns: ${props => props.gridTemplateColumns || 'auto'};
    border-bottom: 1px solid #ededed;
  }

  .noRecord {
    padding: 20px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .historyItem {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .noRecord {
    font-size: 14px;
  }

  .historyList {
    border: 1px solid #ededed;
    border-radius: 0 0 10px 10px;

    .historyItem {
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
`;

export const RowItemStyled = styled.div`
  display: grid;
  //grid-gap: 10px;
  padding: 15px 0;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #222222;
  &:hover {
    background-color: #F9F9FA;
  }

  > div:first-child {
    padding-left: 20px;
  }
  > div:last-child {
    padding-right: 20px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 10px;
    > div:first-child {
      padding-left: 0px;
    }
  }
`;

export const ColumnItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  .columnItem {
    &-title {
      display: none;
    }
    &-value {
      font-size: 13px;
      color: #262424;
      * {
        font-size: inherit;
        color: inherit;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .columnItem {
      &-title {
        display: block;
        font-size: 13px;
        color: #aeaeae;
        font-weight: 500;
      }
    }
  }
`;
