import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import Loading from 'src/components/loading';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ErrorOverlay from '../errorOverlay';

const PickerMobileStyled = styled.div`
  width: 100%;
  /* margin-bottom: 10px; */
  .dropdown-warper button{
    padding-right: 30px;
  }
  .disabled {
     pointer-events: none;
     opacity: 0.75;
  }
  .sideBar {
    opacity: 0;
    display: none;
    &.active {
      display: block;
      top: 30vh;
      opacity: 1;
      display: initial;
    }
    transition: all 0.3s;
    position: fixed;
    z-index: 999999;
    width: 100%;
    background: white;
    height: 70vh;
    left: 0;
    top: 100vh;
    box-shadow: 0 -10px 10px -10px white;
    .dropdown {
      height: 70vh;
      font-size: 18px;
      overflow-y: scroll;
      > div {
        padding: 10px 20px;
        color: #979797;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &.selected {
          background: #EDF1FF;
          color: #003DBC;
        }
      }
    }
    .searchBox {
      width: 100%;
      padding: 10px;
      position: relative;
      input {
        width: 100% !important;
        outline: none;
        border: 1px solid #E4E7F2 !important;
        background-color: #fff !important;
        max-width: 100%;
        border-radius: 20px;
      }
      i {
        position: absolute;
        top: 50%;
        color: #6f6c6c;
        right: 20px;
        transform: translate(0, -50%);
        font-size: initial;
      }
    }
    .opacityDropdown {
      position: absolute;
      height: 65px;
      background: white;
      width: 100%;
      opacity: 0.8;
      &-bottom {
        bottom: 0;
      }
      &-top {
        top: 0;
      }
    }
  }
  .overlay {
    display: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.active {
      display: block;
      opacity: 1;
    }
    top: 0px;
    left: 0px;
    position: fixed; 
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0, 0.7);
    z-index: 999998;
    display: none; // active display block opacity 1
    transition: all 0.3s ease-in-out;
  }
`;


const defaultListProps = [
  { value: -1, label: 'Please set list props'}
];
// list default need have value and label
/**
 * searchable: props
 * handleSearch: (arr, text) => return newArr;
 * cache: props if u want to save the search changes
 * list: array of object { value: '', label: '' }
 */
const PickerMobile = ({
  list = defaultListProps, defaultValue, value = null,
  onChangeValue = f => f, onChange = f => f, isUpdatingRealtime = false,
  handleItemOnChange, searchable = undefined,
  handleSearch = undefined, cache = undefined,
  valueField = 'value', labelField = 'label', searchFields = [labelField], disabled, toggle = ' ',
  meta, zIndex,
  ...restProps
}) => {
  let searchRef = null;
  const targetRef = useRef(null);
  const { input } = restProps;
  const isRefuxField = input !== undefined;
  const [open, setOpen ] = useState(false);
  const [search, setSearch] = useState('');
  const [targetElement, setTargetElement] = useState(null);
  const _selected = isRefuxField ? list.find(e => {
    if(isRefuxField && typeof input.value === 'object' && input.value[valueField]){
      return e[valueField] == (input.value[valueField]  || defaultValue);
    }
    return e[valueField] == (input.value || defaultValue);
  }) || {} : defaultValue ? defaultValue : {};
  const [selected, setSelected] = useState(_selected);
  if (!isRefuxField && value && value[valueField] !== selected[valueField]) {
    setSelected(value);
  }
  if (isRefuxField && _selected[valueField] !== selected[valueField]) {
    setSelected(_selected);
  }
  const { error, touched } = meta || {};
  const shouldShowError = !!(touched && error);

  useEffect(()=> {
    setTargetElement(targetRef.current);
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handleOpenPicker = () => {
    if (typeof cache == 'undefined') {
      setSearch('');
    }
    //searchRef?.focus();
    setOpen(true);
    document.body.classList.toggle('noscroll', true);
    disableBodyScroll(targetElement, {
      allowTouchMove: el => {
        while (el && el !== document.body) {
          if (el.getAttribute('body-scroll-lock-ignore') !== null) {
            return true;
          }

          el = el.parentNode;
        }
      },
    });
  };
  const handleClosePicker = () => {
    enableBodyScroll(targetElement);
    document.body.classList.toggle('noscroll', false);
    setOpen(false);
  };
  const handleOnClickItem = e => {
    setSelected(e);
    handleClosePicker();
    if (isRefuxField) {
      input.onChange(e[valueField]);
    } else {
      onChangeValue(e[valueField]);
      onChange(e);
    }
    if(typeof handleItemOnChange === 'function') {
      handleItemOnChange(e);
    }
    if (typeof e?.onClick === 'function') {
      e?.onClick();
    }
  };
  const filterList = arr => {
    if (typeof handleSearch !== 'undefined') {
      return handleSearch(arr, search);
    }
    return search.trim() === '' ? arr : arr.filter(e => {
      let matched = false;
      for(let i of searchFields){
        matched = (typeof e[i] === 'string') ? e[i].toLowerCase().indexOf(search.toLocaleLowerCase()) >= 0 : false;
        if(matched) break;
      }
      return matched;
    });
  };
  const onSearchChange = e => {
    setSearch(e.target.value);
  };
  const sideBarCss = () => open ? 'sideBar active': 'sideBar';
  const overlayCss = () => open ? 'overlay active' : 'overlay';
  const dropdownStyle = () => {
    // const documentHeight = document.body.offsetHeight;
    // const windowHeight = window.innerHeight;
    const navBarHeight = 60;
    return {
      height: `calc(70vh - ${navBarHeight}px)`
    };
  };
  return (
    <PickerMobileStyled ref={targetRef}>
      <div
        className={cx('dropdown-warper dropdown', disabled ? 'disabled': '')}
        role="presentation"
        onClick={(e)=> {
          e.preventDefault();
          return handleOpenPicker();
        }}
      >
        <button type="button" className="dropdown-toggle btn btn-basic">
          {selected.activeLabel
            ? selected.activeLabel
            :  selected[labelField]
              ? selected[labelField]
              : isUpdatingRealtime
                ? <div style={{ margin: '0 auto' }}><Loading /></div>
                : toggle
          }
        </button>
      </div>
      <div className={sideBarCss()} role="presentation" onClick={e => e}>
        {typeof searchable !== 'undefined' && (
        <div className="searchBox">
          <input ref={ref => searchRef = ref} value={search} onChange={onSearchChange} />
          <i className="far fa-search"></i>
        </div>
        )}
        <div className="dropdown" body-scroll-lock-ignore="true" style={dropdownStyle()}>
          {filterList(list).map((e, i) => (
            <div role="presentation" onClick={() => handleOnClickItem(e)} className={e[valueField] === selected[valueField] ? 'selected' : ''} key={i}>
              {e[labelField]}
            </div>
          ))}
          <div style={{height: '120px'}}></div>
        </div>
      </div>
      <div className={overlayCss()} role="presentation" onClick={handleClosePicker} />
      <ErrorOverlay placement="bottom" target={targetRef} shouldShowError={shouldShowError} error={error} zIndex={zIndex} />
    </PickerMobileStyled>
  );
};

export default PickerMobile;
